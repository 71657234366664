.border {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  border: solid 0.5px #ccc;
  border-radius: 10px;
}

.icon {
  margin-right: 10px;
  fill: #0009;
}

.setting {
  margin-left: 10px;
  border-left: solid 0.5px #ccc;
}

.setting svg {
  width: 16px;
  height: 16px;
  margin-right: 0;
  margin-left: 10px;
  fill: #0009;
}

.flex-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flex-row:hover {
  opacity: 0.7;
}

.m-0 {
  margin: 0;
}
