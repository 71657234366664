#root {
  margin: 1em auto 0;
}

.title {
  text-align: center;
}

@media screen and (max-width: 400px) {
  .title {
    font-size: 20px;
  }
}
