/* stylelint-disable */
.number-board {
  margin-top: 15px !important;
}

.fluid-button {
  margin-top: 10px;
}

.input-area {
  width: 80px;
  padding: 10px;
  font-size: 60px;
  text-align: center;
  border: none;
}

.input-segment {
  padding: 5px;
  margin-bottom: 5px;
  font-size: 50px;
  background-color: #fff;
  border-radius: 10px;
}

.input-segment p {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  color: #fff;
  background: #f55676 radial-gradient(circle, transparent 1%, #ffcdd2 1%)
    center/15000%;
  border-color: #fff;
}

.ripple:hover svg {
  fill: #fff;
}

.ripple:active {
  background-color: #ffebee;
  background-size: 100%;
  transition: background 0s;
}

/* Button style */
.icon-button {
  width: 50px;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 4px #999;
}

.large-icon-button {
  width: 70px;
  height: 70px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}

.large-icon-button:hover .play {
  fill: #ff1744;
}

.large-icon-button:hover .break-hover {
  fill: #64b5f6;
}

.pause {
  fill: #ff1744;
}

.pause:hover {
  fill: #0009;
}

button {
  padding: 12px;
  color: #0009;
  background-color: #fff;
  border: none;
  outline: none;
}

.theme-color {
  fill: #ff1744;
}

.tomato {
  width: 98vw;
  min-width: 300px;
  max-width: 400px;
  height: 98vw;
  min-height: 300px;
  max-height: 400px;
  padding: 20px;
  border: solid 5px #ccc;
  border-radius: 50%;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.flex-row-tomato {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tomato-start {
  background-position: bottom;
  background-size: 100%;
  border: solid 5px #ff1744;
  -webkit-transition: all 2s;
  transition: all 1s;
}

.start-button {
  position: absolute;
  margin-top: 10px;
  offset-position: center;
}

.start-stop {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.replay {
  position: relative;
  top: 5px;
  left: 90px;
  width: 50px;
  height: 50px;
  padding: 10px;
  background-position: center;
  border-radius: 50%;
  transition: background 0.8s;
}

.edit {
  position: relative;
  top: 5px;
  left: -90px;
  width: 50px;
  height: 50px;
  padding: 10px;
  background-position: center;
  border-radius: 50%;
  transition: background 0.8s;
}

.replay svg {
  fill: #0009;
}

.replay:hover {
  background: #ccc
    radial-gradient(circle, transparent 1%, rgb(223, 222, 222) 1%) center/15000%;
}

.replay:hover svg {
  fill: #fff;
}

.replay:active {
  background-color: #ccc;
  background-size: 100%;
  transition: background 0s;
}

.on {
  border-bottom: solid 0.5px #ff1744;
  transition: all 1s;
}

.off {
  border-bottom: solid 0.5px #fff;
  transition: all 1s;
}

.break-point {
  border-color: #64b5f6;
  fill: #64b5f6;
  transition: all 1s;
}

@media screen and (max-width: 400px) {
  .input-area {
    font-size: 40px;
  }

  .input-segment p {
    font-size: 10px;
  }

  .large-icon-button {
    width: 60px;
    height: 60px;
  }

  .start-button {
    margin-top: 0;
  }
}
.tweet-button {
  text-decoration: none;
  border: solid 0.5px #ccc;
  border-radius: 30px;
  font-size: 12px;
  padding: 10px 15px;
  color: #0009;
}
.tweet-button:hover {
  background: #1da1f2 radial-gradient(circle, transparent 1%, #ffcdd2 1%)
    center/15000%;
}
